<template>
  <v-card style="height: 100vh" class="rounded-drawer">
    <v-navigation-drawer
      style="height: 100%"
      v-model="drawer"
      :rail="rail"
      permanent
      @click="rail = false"
    >
      <v-list
        density="compact"
        nav
        style="height: 100%"
        :color="WEBAPPPRO_DRAWER_COLOR"
      >
        <v-list-item
          ><svg
            class="mt-5"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.0673 14.0308H22.3547V26.1846H24.0367C25.474 26.1846 26.4832 26.0308 27.0948 25.6923C27.7064 25.3846 28.1651 24.8308 28.5321 24.0308C28.8685 23.2308 29.052 21.9385 29.052 20.1538C29.052 17.7846 28.6544 16.1846 27.8899 15.3231C27.1254 14.4615 25.841 14.0308 24.0673 14.0308Z"
              fill="white"
            />
            <path
              d="M31.8349 0H8.13456C3.63914 0 0 3.66154 0 8.15385V31.8462C0 36.3385 3.63914 40 8.13456 40H31.8349C36.3303 40 39.9694 36.3385 39.9694 31.8462V8.15385C39.9694 3.66154 36.3303 0 31.8349 0ZM11.5596 31.2615H5.38226V15.1385H11.5596V31.2615ZM11.5596 13.2H5.38226V8.98462H11.5596V13.2ZM35.1376 25.5692C34.5872 26.8923 33.8532 27.9692 32.8746 28.8615C31.9266 29.7538 30.8869 30.3385 29.7859 30.6462C28.2875 31.0462 26.9113 31.2615 25.6881 31.2615H15.474V8.98462H25.6881C27.7064 8.98462 29.3272 9.26154 30.581 9.81538C31.8043 10.3692 32.844 11.1385 33.6697 12.1538C34.4954 13.1692 35.0765 14.3692 35.4434 15.7231C35.8104 17.0769 35.9939 18.5231 35.9939 20.0308C35.9939 22.4 35.7187 24.2462 35.1682 25.5692H35.1376Z"
              fill="white"
            />
          </svg>
        </v-list-item>
        <v-list-item
          title="Accueil"
          :class="{ selected: selectedItem === 'home' }"
          @click="setFocus('home', showHome)"
          ><svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28.66 15.1611L21.66 10.5011C21.1681 10.1743 20.5906 10 20 10C19.4094 10 18.8319 10.1743 18.34 10.5011L11.34 15.1611C10.9278 15.435 10.5897 15.8066 10.3559 16.2427C10.1221 16.6789 9.99982 17.1662 10 17.6611V26.5911C10 27.3868 10.3161 28.1498 10.8787 28.7124C11.4413 29.275 12.2044 29.5911 13 29.5911H27C27.7956 29.5911 28.5587 29.275 29.1213 28.7124C29.6839 28.1498 30 27.3868 30 26.5911V17.6611C30.0002 17.1662 29.8779 16.6789 29.6441 16.2427C29.4103 15.8066 29.0722 15.435 28.66 15.1611ZM18 27.5911V22.5911C18 22.0607 18.2107 21.552 18.5858 21.1769C18.9609 20.8018 19.4696 20.5911 20 20.5911C20.5304 20.5911 21.0391 20.8018 21.4142 21.1769C21.7893 21.552 22 22.0607 22 22.5911V27.5911H18ZM28 26.5911C28 26.8563 27.8946 27.1107 27.7071 27.2982C27.5196 27.4858 27.2652 27.5911 27 27.5911H24V22.5911C24 21.5303 23.5786 20.5128 22.8284 19.7627C22.0783 19.0125 21.0609 18.5911 20 18.5911C18.9391 18.5911 17.9217 19.0125 17.1716 19.7627C16.4214 20.5128 16 21.5303 16 22.5911V27.5911H13C12.7348 27.5911 12.4804 27.4858 12.2929 27.2982C12.1054 27.1107 12 26.8563 12 26.5911V17.6611C12.0009 17.4964 12.0424 17.3345 12.1209 17.1897C12.1994 17.0449 12.3124 16.9217 12.45 16.8311L19.45 12.1611C19.6133 12.0536 19.8045 11.9963 20 11.9963C20.1955 11.9963 20.3867 12.0536 20.55 12.1611L27.55 16.8311C27.6876 16.9217 27.8006 17.0449 27.8791 17.1897C27.9576 17.3345 27.9991 17.4964 28 17.6611V26.5911Z"
              fill="white"
            />
          </svg>
        </v-list-item>
        <v-list-item
          v-if="WEBAPPPRO_ALLOW_WALLET == 1"
          title="Porte-monnaie"
          :class="{ selected: selectedItem === 'wallet' }"
          @click="setFocus('wallet', showWalletCharge)"
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.7129 15.1909H26.556V14.3166C26.556 12.4877 25.0838 11 23.2712 11H11.7388C10.2291 11 9 12.2401 9 13.7657V24.6834C9 26.5123 10.4744 28 12.2871 28H26.7129C28.5256 28 30 26.5123 30 24.6834V18.5075C30 16.6786 28.5256 15.1909 26.7129 15.1909ZM11.7388 12.3382H23.2712C24.3521 12.3382 25.2297 13.2259 25.2297 14.3166V15.1909H11.7388C11.3608 15.1909 11.0072 15.0437 10.7397 14.7738C10.4722 14.5062 10.3263 14.1471 10.3263 13.7657C10.3263 12.9784 10.9607 12.3382 11.7388 12.3382ZM28.6737 23.0775H27.2059C26.3968 23.0775 25.7381 22.4129 25.7381 21.5943C25.7381 20.778 26.3968 20.1134 27.2059 20.1134H28.6737V23.0775ZM28.6737 18.7751H27.2059C25.6652 18.7751 24.4118 20.0398 24.4118 21.5943C24.4118 23.1511 25.6652 24.4158 27.2059 24.4158H28.6737V24.6834C28.6737 25.7741 27.7939 26.6618 26.7129 26.6618H12.2871C11.2061 26.6618 10.3263 25.7741 10.3263 24.6834V16.1366C10.7485 16.3931 11.2326 16.5291 11.7388 16.5291H26.7129C27.7939 16.5291 28.6737 17.4168 28.6737 18.5075V18.7751Z"
              fill="white"
            />
          </svg>
        </v-list-item>
        <v-list-item
          title="Prendre une photo"
          :class="{ selected: selectedItem === 'newShoot' }"
          @click="setFocus('newShoot', newShoot)"
          ><svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.1496 18.8049C13.8025 18.8049 14.3318 18.2845 14.3318 17.6427C14.3318 17.0008 13.8025 16.4805 13.1496 16.4805C12.4966 16.4805 11.9673 17.0008 11.9673 17.6427C11.9673 18.2845 12.4966 18.8049 13.1496 18.8049Z"
              fill="#FAFAFC"
            />
            <path
              d="M28.0324 13.5636H25.691L25.4824 13.1306C25.0651 12.3103 24.2306 11.7861 23.3033 11.7861H16.7428C15.8155 11.7861 14.9578 12.3103 14.5637 13.1306L14.355 13.5636H11.9905C10.3446 13.5636 9 14.8853 9 16.5033V25.8465C9 27.4644 10.3446 28.7861 11.9905 28.7861H28.0092C29.6551 28.7861 30.9997 27.4644 30.9997 25.8465V16.5033C31.0229 14.8853 29.6783 13.5636 28.0324 13.5636ZM29.4001 25.8692C29.4001 26.6213 28.7742 27.2365 28.0092 27.2365H11.9905C11.2255 27.2365 10.5996 26.6213 10.5996 25.8692V16.5261C10.5996 15.7741 11.2255 15.1588 11.9905 15.1588H14.8187C15.12 15.1588 15.3982 14.9765 15.5373 14.7258L15.9778 13.8599C16.1169 13.5864 16.3951 13.4041 16.7196 13.4041H23.2801C23.6047 13.4041 23.8828 13.5864 24.0219 13.8599L24.4624 14.7258C24.6015 14.9993 24.8797 15.1588 25.181 15.1588H28.0092C28.7742 15.1588 29.4001 15.7741 29.4001 16.5261V25.8692Z"
              fill="#FAFAFC"
            />
            <path
              d="M20.0115 15.7739C17.276 15.7739 15.0505 17.9616 15.0505 20.6506C15.0505 23.3396 17.276 25.5501 20.0115 25.5501C22.747 25.5501 24.9724 23.3624 24.9724 20.6734C24.9724 17.9844 22.747 15.7739 20.0115 15.7739ZM20.0115 23.9549C18.1569 23.9549 16.6501 22.4737 16.6501 20.6506C16.6501 18.8275 18.1569 17.3691 20.0115 17.3691C21.866 17.3691 23.3729 18.8503 23.3729 20.6734C23.3729 22.4964 21.866 23.9549 20.0115 23.9549Z"
              fill="#FAFAFC"
            />
          </svg>
        </v-list-item>
        <v-list-item
          title="Prise de photo en attentes"
          :class="{ selected: selectedItem === 'waitingShoot' }"
          @click="setFocus('waitingShoot', showWaittingShootList)"
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 10C14.5 10 10 14.5 10 20C10 20.7 10.1 21.5 10.2 22.2C10.3 22.7 10.7 23 11.2 23C11.3 23 11.3 23 11.4 23C11.9 22.9 12.3 22.3 12.2 21.8C12.1 21.2 12 20.6 12 20C12 15.6 15.6 12 20 12C24.4 12 28 15.6 28 20C28 24.4 24.4 28 20 28C19.4 28 18.8 27.9 18.2 27.8C17.7 27.7 17.2 28 17 28.6C16.8 29.2 17.2 29.7 17.8 29.8C18.5 30 19.3 30 20 30C25.5 30 30 25.5 30 20C30 14.5 25.5 10 20 10Z"
              fill="white"
            />
            <path
              d="M18.9999 16C18.3999 16 17.9999 16.4 17.9999 17V21C17.9999 21.6 18.3999 22 18.9999 22H22.9999C23.5999 22 23.9999 21.6 23.9999 21C23.9999 20.4 23.5999 20 22.9999 20H19.9999V17C19.9999 16.4 19.5999 16 18.9999 16Z"
              fill="white"
            />
            <path
              d="M13.4001 24.5C13.1001 24 12.5001 23.9 12.0001 24.2C11.5001 24.5 11.4001 25.1 11.7001 25.6C12.4001 26.6 13.3001 27.5 14.3001 28.2C14.5001 28.3 14.7001 28.4 14.9001 28.4C15.2001 28.4 15.5001 28.2 15.7001 28C16.0001 27.5 15.9001 26.9 15.4001 26.6C14.7001 26 14.0001 25.3 13.4001 24.5Z"
              fill="white"
            />
          </svg>
        </v-list-item>
        <v-list-item
          title="Prises de photo réalisées"
          :class="{ selected: selectedItem === 'orderList' }"
          @click="setFocus('orderList', showOrderList)"
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.2835 30.9042H14.5696C12.5365 30.9042 10.8669 29.3771 10.6859 27.3519L9.92235 18.8035C9.87078 18.227 10.0653 17.6513 10.456 17.2244C10.8467 16.7972 11.4026 16.5522 11.9815 16.5522H27.8715C28.4505 16.5522 29.0064 16.7972 29.3971 17.2244C29.7877 17.6513 29.9823 18.227 29.9307 18.8035L29.1672 27.3519C28.9861 29.3771 27.3165 30.9042 25.2835 30.9042ZM11.9815 17.7051C11.7217 17.7051 11.4821 17.8107 11.3067 18.0023C11.1315 18.1942 11.0475 18.4421 11.0707 18.7008L11.8342 27.2493C11.9617 28.6757 13.1375 29.7513 14.5696 29.7513H25.2835C26.7155 29.7513 27.8914 28.6757 28.0188 27.2493L28.7823 18.7008C28.8055 18.4421 28.7216 18.1942 28.5464 18.0023C28.371 17.8107 28.1314 17.7051 27.8715 17.7051H11.9815Z"
              fill="white"
              stroke="white"
              stroke-width="0.6"
            />
            <path
              d="M24.7831 20.9827C24.4647 20.9827 24.2067 20.7247 24.2067 20.4063V14.2372C24.2067 11.985 22.3745 10.1529 20.1224 10.1529H19.7306C17.4784 10.1529 15.6463 11.985 15.6463 14.2372V20.4063C15.6463 20.7247 15.3882 20.9827 15.0698 20.9827C14.7514 20.9827 14.4934 20.7247 14.4934 20.4063V14.2372C14.4934 11.3494 16.8428 9 19.7306 9H20.1224C23.0101 9 25.3595 11.3494 25.3595 14.2372V20.4063C25.3595 20.7247 25.1015 20.9827 24.7831 20.9827Z"
              fill="white"
              stroke="white"
              stroke-width="0.6"
            />
            <path
              d="M26.1626 20.9824H23.4038C23.0854 20.9824 22.8274 20.7244 22.8274 20.406C22.8274 20.0876 23.0854 19.8296 23.4038 19.8296H26.1626C26.481 19.8296 26.739 20.0876 26.739 20.406C26.739 20.7244 26.481 20.9824 26.1626 20.9824Z"
              fill="white"
              stroke="white"
              stroke-width="0.6"
            />
            <path
              d="M16.4493 20.9824H13.6906C13.3722 20.9824 13.1141 20.7244 13.1141 20.406C13.1141 20.0876 13.3722 19.8296 13.6906 19.8296H16.4493C16.7677 19.8296 17.0257 20.0876 17.0257 20.406C17.0257 20.7244 16.7677 20.9824 16.4493 20.9824Z"
              fill="white"
              stroke="white"
              stroke-width="0.6"
            />
          </svg>
        </v-list-item>

        <v-list-item
          title="Déconnexion"
          class="logout-item"
          :class="{ selected: selectedItem === 'logout' }"
          @click="setFocus('logout', logout)"
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.9808 25.2105C23.4979 25.2105 23.0912 25.6172 23.0912 26.1002V28.1336C23.0912 28.2098 23.0403 28.2607 22.9641 28.2607H13.8646C13.7883 28.2607 13.7375 28.2098 13.7375 28.1336V11.8663C13.7375 11.79 13.7883 11.7392 13.8646 11.7392H22.9641C23.0403 11.7392 23.0912 11.79 23.0912 11.8663V13.8997C23.0912 14.3826 23.4979 14.7893 23.9808 14.7893C24.4637 14.7893 24.8704 14.3826 24.8704 13.8997V11.8663C24.8704 10.8242 24.0062 9.95996 22.9641 9.95996H13.8646C12.8225 9.95996 11.9583 10.8242 11.9583 11.8663V28.1336C11.9583 29.1757 12.8225 30.0399 13.8646 30.0399H22.9641C24.0062 30.0399 24.8704 29.1757 24.8704 28.1336V26.1002C24.8704 25.6172 24.4637 25.2105 23.9808 25.2105Z"
              fill="white"
            />
            <path
              d="M31.7331 19.3902C31.7331 19.3648 31.7331 19.3648 31.7331 19.3902L27.5646 15.2726C27.2088 14.9167 26.6496 14.9421 26.2937 15.2726C25.9379 15.6284 25.9633 16.1876 26.2937 16.5435L28.9118 19.1106H17.728C17.2451 19.1106 16.8384 19.5173 16.8384 20.0003C16.8384 20.4832 17.2451 20.8899 17.728 20.8899H28.9118L26.3192 23.4571C25.9633 23.8129 25.9633 24.3721 26.3192 24.7279C26.675 25.0838 27.2342 25.0838 27.59 24.7279L31.7331 20.6357C32.089 20.3053 32.089 19.7207 31.7331 19.3902Z"
              fill="white"
            />
          </svg>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>
<script>
import { localStorageService } from '@/services/localStorage.service'
export default {
  data() {
    return {
      isVisible: false,
      agenceUuid: null,
      agenceId: null,
      credit: 0,
      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      color1: localStorageService.getStyle(location.hostname, 'color1'),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      drawer: true,
      rail: true,
      focusedIcon: null,
      selectedItem: '',
      WEBAPPPRO_DRAWER_COLOR: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_DRAWER_COLOR',
      ),
      WEBAPPPRO_ALLOW_WALLET: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ALLOW_WALLET',
      ),
    }
  },
  methods: {
    showHome() {
      this.$router.push({
        name: 'Home-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
    showWalletCharge() {
      this.$router.push({
        name: 'Wallet-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
    showWaittingShootList() {
      this.$router.push({
        name: 'Waiting-orders-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
    showOrderList() {
      this.$router.push({
        name: 'Orders-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
    newShoot() {
      this.$router.push({
        name: 'New-order-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
          agence_id: this.agenceId,
          agence_uid: this.agenceUuid,
        },
      })
    },
    logout() {
      localStorageService.clearAll()
      this.$router.push({
        name: 'Authentication-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
    setFocus(item, action) {
      // Si c'est le même élément, ne rien faire pour éviter de déclencher l'action deux fois
      if (this.selectedItem === item) return

      // Mettre immédiatement à jour l'état sélectionné
      this.selectedItem = item

      // Forcer la mise à jour visuelle en utilisant $forceUpdate
      this.$forceUpdate()

      // Exécuter l'action après la mise à jour
      action()
    },
  },
}
</script>
<style scoped>
.logout-item {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: 50px;
}
.rounded-drawer {
  border-radius: 0 40px 40px 0 !important;
  width: 100px !important;
  width: 100px;
}
.photo-icon {
  margin: auto;
  margin-top: 40px;
  width: 39.97px;
  height: 40px;
  margin-bottom: 40px;
}
.selected {
  background-color: rgba(0, 123, 255, 0.1);
  border-left: 4px solid white;
}
</style>
